// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@popperjs/core";
import "../lib/bootstrap_files";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "@fortawesome/fontawesome-free/js/all";
import "unpoly";
import "ahoy.js";
import { toBlob, toPng } from "html-to-image";
import lottie_light from "lottie-web/build/player/lottie_light";
import "inputmask/dist/jquery.inputmask";
import $ from "jquery";
import "select2";
import "jstree";
import Croppie from "croppie";

up.layer.config.modal.size = "large";
up.layer.config.modal.dismissable = "button";
up.fragment.config.runScripts = true;

up.on("up:fragment:loaded", (event) => {
  const status = event.response.status;
  const error = status == 400 || status == 401 || status == 404;

  if (error) {
    // Prevent the fragment update and don't update browser history
    event.preventDefault();

    // Make a full page load for the same request.
    event.request.loadPage();
  }
});

up.on("up:request:late", (event, form) => {
  if (up.layer.isOverlay()) {
    const $disableWith = $(
      up.layer.element.querySelector("[data-disable-with]")
    );
    $disableWith
      .attr("disabled", true)
      .data("og-text", $disableWith.val() || $disableWith.text())
      .val($disableWith.data("disable-with"))
      .text($disableWith.data("disable-with"));
    $(up.layer.element.querySelector("[up-dismiss]")).addClass("disabled");
    $(up.layer.element.querySelector("up-modal-dismiss")).hide();
  }
});

up.on("up:request:recover", (event, form) => {
  if (up.layer.isOverlay()) {
    const $disableWith = $(
      up.layer.element.querySelector("[data-disable-with]")
    );
    $disableWith
      .removeAttr("disabled", true)
      .val($disableWith.data("og-text"))
      .text($disableWith.data("og-text"));
    $(up.layer.element.querySelector("[up-dismiss]")).removeClass("disabled");
    $(up.layer.element.querySelector("up-modal-dismiss")).show();
  }
});

$.fn.select2.defaults.set("theme", "bootstrap-5");
$.fn.select2.defaults.set("width", "100%");

global.autosubmitTimeout = null;

global.select2Parent = () => {
  return up.layer.isRoot() ? $(document.body) : $(up.layer.current.element);
};

global.navLinkInit = ($elem) => {
  const params = new URLSearchParams(location.search.substring(1));
  const tabName = $elem.data("bs-target")?.substring(1);
  if (!tabName) return;

  if (tabName == params.get("tab") || tabName == up.context.bs_tab) {
    $elem.tab("show");
  }
  $elem.on("shown.bs.tab", (event) => {
    params.set("tab", tabName);
    up.context.bs_tab = tabName;
    history.replaceState(
      null,
      document.title,
      `${location.origin + location.pathname}?${params.toString()}`
    );
    if (params.get("tab") == "investment-mandates") {
      $(".profile .share-button").text("Share Mandates");
    } else if (params.get("tab") == "overview") {
      $(".profile .share-button").text("Share Profile");
    } else if (params.get("tab") == "portfolio-assets") {
      $(".profile .share-button").text("Share Portfolio");
    }
  });
};

global.decimalMask = (elem) => {
  $(elem).inputmask("currency", {
    removeMaskOnSubmit: true,
    digits: 3,
    autoUnmask: true,
    digitsOptional: true,
    placeholder: "0",
    max: 99999,
    rightAlign: false,
  });
};

global.currencyMask = (elem) => {
  $(elem).inputmask("currency", {
    removeMaskOnSubmit: true,
    digits: 0,
    rightAlign: false,
  });
};

global.jstreeInit = ($tree, data) => {
  if (!data) {
    data = JSON.parse($tree.attr("up-data"));
  }
  $tree.jstree({
    core: {
      worker: false,
      data: data,
    },
    plugins: ["checkbox", "search"],
  });

  $tree.on("ready.jstree", (event) => {
    $tree.jstree(true).close_all();
    $tree
      .jstree(true)
      .get_undetermined()
      .forEach((node) => {
        $tree.jstree(true).open_node(node);
      });
  });

  $tree
    .siblings(".industry-filter")
    .find(".apply-filter")
    .click((event) => {
      event.preventDefault();
      $(event.target)
        .parents(".industry-filter")
        .siblings(".industry-tree")
        .jstree(true)
        .search($(event.target).siblings("input").val());
    });

  $tree
    .siblings(".industry-filter")
    .find(".clear-filter")
    .click((event) => {
      event.preventDefault();
      $(event.target).siblings("input").val("");
      $(event.target)
        .parents(".industry-filter")
        .siblings(".industry-tree")
        .jstree(true)
        .search("");
    });

  $tree.on(
    "changed.jstree ready.jstree after_close.jstree open_node.jstree",
    (event, data) => {
      $(".jstree-ocl").each((index, el) => {
        $(el).toggleClass(
          "icon-plus",
          $(el).parent().hasClass("jstree-closed")
        );
        $(el).toggleClass("icon-minus", $(el).parent().hasClass("jstree-open"));
      });

      $tree.prev(".selected-industries").empty();

      $tree
        .jstree(true)
        .get_selected(true)
        .forEach((node) => {
          $tree
            .prev(".selected-industries")
            .append(
              $(
                `<input type="hidden" name="${$tree.attr("name")}" value="${
                  node.li_attr["data-id"]
                }" />`
              )
            );
        });
    }
  );
};

function createModalFragmentLink(link) {
  $(link).attr({
    "up-layer": $(link).attr("up-layer") || "new",
    "up-target": $(link).attr("up-target") || ".up-modal-fragment",
    "up-fail-target": $(link).attr("up-fail-targe") || "form",
    "up-context": JSON.stringify({
      return_path: location.href,
      fragment: $(link).attr("up-modal-fragment"),
    }),
  });

  $(link).on("click", (event) => {
    event.preventDefault();
    up.follow(event.currentTarget, {
      onAccepted: globalInit,
      onDismissed: globalInit,
    });
  });
}
function globalInit(event) {
  $("input, select")
    .filter('[required="required"]')
    .each((index, input) => {
      $("label")
        .filter(`[for=${$(input).attr("id")}]`)
        .addClass("required");
    });

  $("[data-bs-toggle=tooltip]").each((index, tip) => {
    $(tip).tooltip({
      placement: "bottom",
    });
  });

  $(document).on("keyup", function (e) {
    if (e.key === "Escape" && up.layer.isOverlay() && !up.network.isBusy()) {
      up.layer.dismiss();
    }
  });

  $(".nav-link").each((index, link) => {
    navLinkInit($(link));
  });

  $(window).on("show.bs.dropdown", function (e) {
    const dropdownMenu = $(e.target).next(".dropdown-menu");

    // detach it and append it to the body
    $("body").append(dropdownMenu.detach());

    // grab the new offset position
    var eOffset = $(e.target).offset();

    // make sure to place it where it would normally go (this could be improved)
    dropdownMenu.css({
      display: "block",
      top: eOffset.top - 5,
      left:
        eOffset.left - dropdownMenu.outerWidth() + $(e.target).outerWidth() + 5,
    });
  });

  // and when you hide it, reattach the drop down, and hide it normally
  $(window).on("hide.bs.dropdown", function (e) {
    const dropdownMenu = $(".dropdown-menu.show");
    $(e.target).after(dropdownMenu.detach());
    dropdownMenu.hide();
    dropdownMenu.removeAttr("style");
  });

  $("up-modal-dismiss span").text("");

  $("[up-modal-fragment]").each((index, link) => {
    createModalFragmentLink(link);
  });

  $(document).on("focus", ".select2.select2-container", function (e) {
    // only open on original attempt - close focus event should not fire open
    if (e.originalEvent) {
      const container = $(this);
      if (!container.hasClass("select2-container--focus")) {
        $(this).siblings("select").select2("open");
        if ($(this).find(".select2-selection--single").length > 0) {
          const search = document.querySelector(
            `[aria-controls=${$(e.target).attr("aria-owns")}]`
          );
          if (search) {
            search.focus();
          }
        }
      }
    }
  });

  $(document.body).css({ paddingBottom: $("footer:last").outerHeight(true) });
  $(window).on("resize", (event) => {
    $(document.body).css({
      paddingBottom: $("footer:last").outerHeight(true),
    });
  });
}

up.on("up:layer:opened", globalInit);
up.macro("body", () => {
  globalInit();
});
$(document).on("turbolinks:load", globalInit);
up.$macro("[data-bs-toggle=tooltip]", ($elem) => {
  $elem.tooltip({
    placement: "bottom",
  });
});

up.$compiler(".alert-dismissible", ($elem) => {
  const $container = $elem.parents(".flash-messages");
  $elem.on("closed.bs.alert", (event) => {
    if ($container.children().length == 0) {
      $container.remove();
    }
  });
});

up.$compiler(".lottie-js", ($elem) => {
  lottie_light.loadAnimation({
    container: $elem[0],
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: $elem.data("path")
  })
})

up.$compiler(".progress-bar", ($elem) => {
  $elem.append($('<div class="bar" />').width($elem.data("fill")))
})

up.compiler(".to-image", (elem) => {
  $(elem).outerHeight($(elem).outerWidth() * 0.5225);
  toBlob(elem, { canvasWidth: 1200, canvasHeight: 1200 * 0.5225 }).then(async (blob) => {
    let file = new File([blob], "share.png", {
      type: "image/png",
      lastModified: new Date().getTime(),
    });
    let container = new DataTransfer();
    container.items.add(file);
    $("input[type=file].share-img")[0].files = container.files;
  });
  toPng(elem, { canvasWidth: 1200, canvasHeight: 1200 * 0.5225 }).then((dataUrl) => {
    let img = new Image();
    img.src = dataUrl;
    img.style.padding = 0;
    $(elem).replaceWith(img);
  });
});

up.$compiler(".onboarding-deals", $elem => {
  const parentHeight = $elem.parent().outerHeight()
  let renderHeight = 0;
  $elem.find(".card").each((index, card) => {
    renderHeight = renderHeight + $(card).outerHeight(true)
    if (renderHeight >= parentHeight) {
      $(card).hide()
    }
  })
})

up.compiler(".linkedin-share-form", elem => {
  $(elem).on("submit", event => {
    event.preventDefault()
    up.submit(elem, {
      target: "main",
      layer: "root"
    }).then(() => {
      $(elem).siblings(".linkedin-oauth-form")[0].submit()
    })
  })
})

up.$compiler(".copy-click", ($elem) => {
  $elem.on("click", (event) => {
    navigator.clipboard.writeText($elem.data("copy"));
  });
});

up.$compiler(".investment-mandate-item", ($elem) => {
  $elem.find(".remove-mandate").on("click", async (event) => {
    event.preventDefault();
    let $target = $(event.currentTarget);
    let $mandate = $(event.currentTarget).parents(".investment-mandate-item");
    up.animate($mandate, "move-to-right", {
      duration: 500,
    }).then(() => {
      up.submit($(event.currentTarget).closest("form"), {
        target: ".investment-mandates",
        focus: "keep",
        params: {
          background_save: true,
        },
        context: {
          return_path: location.href,
        },
      }).then((response) => {
        up.navigate({
          url: $target.attr("href"),
          method: $target.attr("up-method"),
          target: $target.attr("up-target"),
        });
      });
    });
  });
});

up.$compiler(".add-mandate", ($elem) => {
  $elem.on("click", (event) => {
    event.preventDefault();
    up.submit($(event.currentTarget).closest("form"), {
      target: ".investment-mandates",
      focus: "keep",
      params: {
        background_save: true,
      },
      context: {
        return_path: location.href,
      },
    }).then((response) => {
      up.follow(event.currentTarget, { layer: "root" }).then((response) => {
        up.animate($(".investment-mandate-item:last"), "move-from-bottom", {
          duration: 300,
        }).then(() => {
          up.reveal($(".investment-mandate-item:last"), {
            top: true,
            padding: 50,
          });
          const input = $(".investment-mandate-item:last input:first")[0];
          input.focus();
          input.select();
        });
      });
    });
  });
});

up.$compiler("[data-increment]", ($elem) => {
  $elem.on("click", (event) => {
    if ($elem.data("increment")) {
      $.ajax($elem.data("increment"), { method: "PATCH" });
    }
  });
});
up.$compiler(".nav-link", ($elem) => {
  navLinkInit($elem);
});

up.$compiler(".invite-user", ($elem) => {
  const $submitBtn = $elem.find(".invite-button");
  $submitBtn.attr("disabled", "disabled");
  $elem.find("#invitation_email").on("input", (event) => {
    if (event.target.value.length > 0) {
      $submitBtn.removeAttr("disabled");
      $submitBtn.val("Use 1 Invite!");

      if (event.target.value.split("@")[1] == $(event.target).data("domain")) {
        $submitBtn.val("Send Free Invite!");
      }
    } else {
      $submitBtn.attr("disabled", "disabled");
      $submitBtn.val("Invite!");
    }
  });
});

up.$macro(".hidden-param-field", $elem => {
  let params = new URLSearchParams(location.search.substring(1))
  const paramName = $elem.attr("name")

  let urlParam = params.get(paramName)
  if (urlParam && urlParam.length > 0) {
    $elem.val(urlParam)
    params.delete(paramName)
    history.replaceState(
      null,
      document.title,
      `${location.origin + location.pathname}?${params.toString()}`
    );
  }
})

up.$compiler("form", ($elem) => {
  if (up.context.return_path) {
    $elem.append(
      `<input type="hidden" name="return_path" value="${up.context.return_path}" />`
    );
  }
});

up.macro("[up-modal-fragment]", createModalFragmentLink);

up.$compiler(".photo-upload-container", ($elem) => {
  const placeholderImgSrc = $elem.find("img").attr("src");
  $elem.find("input[type=file]").on("change", (event) => {
    if (event.target.files.length > 0) {
      $elem
        .find("img")
        .attr(
          "src",
          URL.createObjectURL(event.target.files[event.target.files.length - 1])
        );

      if ($(event.target).data("auto-submit")) {
        $(event.target)
          .closest("form")
          .find("[type=submit]")
          .attr("disabled", true);
        $(event.target)
          .closest("form")
          .find("[up-dismiss]")
          .addClass("disabled");
        $(up.layer.element.querySelector("up-modal-dismiss")).hide();
        up.submit($(event.target).parents("form"), {
          target: ".up-modal-fragment",
          context: {
            return_path: location.pathname + location.search,
          },
        }).then((response) => {
          $(event.target)
            .closest("form")
            .find("[type=submit]")
            .removeAttr("disabled");
          $(event.target)
            .closest("form")
            .find("[up-dismiss]")
            .removeClass("disabled");

          $(up.layer.element.querySelector("up-modal-dismiss")).show();
        });
      } else {
        $(event.target).parents("form").attr("up-target", ".crop-photo");
      }
    } else {
      $elem.find("img").attr("src", placeholderImgSrc);
      $(event.target).parents("form").removeAttr("up-target");
    }
  });
});

up.macro("[data-mask=decimal]", decimalMask);

up.macro("[data-mask=currency]", currencyMask);

up.$compiler("[data-disable]", ($elem) => {
  $elem
    .change(function () {
      $elem
        .parents("form")
        .find($elem.data("disable"))
        .prop("disabled", !this.checked);
    })
    .change();
});

up.macro("[data-mask=phone]", (elem) => {
  $(elem).inputmask("(999) 999-9999");
});

up.$compiler(".croppie-editor", ($elem, data) => {
  const croppie = new Croppie($elem[0], {
    viewport: {
      width: 135,
      height: 135,
      type: $elem.data("shape") || "square",
    },
  });

  $elem.on("update.croppie", (event) => {
    $(".croppie-input").val(JSON.stringify(event.detail));
  });

  croppie.bind({
    url: $elem.data("photo-url"),
    ...data,
  });
});

up.$compiler(".industry-tree", ($elem, data) => {
  jstreeInit($elem, data);
});

up.$compiler(".select2", ($elem) => {
  $elem.select2({ dropdownParent: select2Parent() });
});

up.$compiler(".origination-contact-select", ($elem, data) => {
  $elem.select2({
    data,
    dropdownParent: select2Parent(),
    insertTag: (data, tag) => {
      const existing = data.find((item) => item.email == tag.text);
      const colleagueEmailMatcher = new RegExp(
        `.{1,}@${$elem.data("domain")}`,
        "i"
      );
      // Insert the tag at the end of the results
      if (!existing && tag.text.match(colleagueEmailMatcher)) {
        data.push(tag);
      }
    },
    templateResult: ({ id, text } = data) => {
      if (id == text && text.split("@")[1] == $elem.data("domain")) {
        return `Invite ${text} as origination contact`;
      }
      return text;
    },
    language: {
      noResults: () => {
        return `<small class='invitation-match-criteria'>Invitation email must end with @${$elem.data(
          "domain"
        )}</small>`;
      },
    },
    escapeMarkup: (markup) => {
      return markup;
    },
    matcher: (params, data) => {
      if ($.trim(params.term) === "") {
        return data;
      }

      if (typeof data.text === "undefined") {
        return null;
      }

      if (data.email.indexOf(params.term) > -1) {
        return data;
      }

      // Return `null` if the term should not be displayed
      return null;
    },
    templateSelection: ({ id, text, invited }) => {
      let $state = $("<span class='invited'></span>");
      if (invited || id == text) {
        $state.text(text);
        return $state;
      }
      return text;
    },
  });
});
